.top-page-slider-box .slick-arrow, .top-category-slider-box .slick-arrow{
	top:44%;
}

.container .swipe-list-tab{
	margin-bottom: 0;
}

.container .top-banner{
	margin-bottom: 0;
}

/* TOP */
.top-article-list-item-box{
	padding-bottom: 0!important;
	position: relative;
}

.top-article-list-item-box .top-article-list-item-box__inner{
	padding-bottom: 56%;
	position: relative;
}

.top-article-list-item-box{
	height: auto!important;
	margin-top: -12px;
}

/* youtube埋め込み */
.top-article-list-item-box .top-article-list-item-box__inner iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
}

/* タイトル文字列部分 */
.top-page-slider-box .top-article-list-item .top-article-title-wrap {
	background: linear-gradient(
		180deg,
		rgba(254, 254, 255, 1),
		rgba(235, 235, 250, 1)
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr= "#00000000",endColorstr="#8c000000",GradientType=0);
	padding: 10px 20px;
	color: #000;
	border-left: 1px solid #ebebf2;
	opacity: 1 !important;
	position: static!important;
}

/* タイトル文字を2列目で…にする */
.top-page-slider-box .top-article-list-item .top-article-title-wrap .top-article-title{
	position: relative;
	height: 75px;
	overflow: hidden;
  vertical-align: middle;
}

@media screen and (max-width: 1024px){
	.top-page-slider-box .top-article-list-item .top-article-title-wrap .top-article-title{
		height: 50px;
		line-height: 25px;
	}
}

@media screen and (max-width: 640px) {
	.top-page-slider-box .top-article-list-item .top-article-title-wrap .top-article-title{
		height: 40px;
		line-height: 19px;
	}
}

/* TOP スライダの左右矢印ボタンのホバー時に変形させない */
.slick-arrow:hover {
	width: 40px!important;
}

/* PC TOP:スライダー枠下にタイトル文とSponsoredを表示 */
.top-page-slider-box .top-article-list-item .top-article-title {
	vertical-align: top;
	line-height: 30px;
	overflow: hidden;
}

.top-page-slider-box .top-article-list-item .label-gray {
	position: absolute;
	bottom: 6px;
	top: auto;
	left: unset;
	background: transparent;
	line-height: 26px;
	color: #7A7A7A;
	font-size: 11px;
	font-size: 1.3rem;
	z-index: 20;
	text-align: left;
}

.top-category-slider-box .cont .img-wrap__title .label-gray {
	position: absolute;
	bottom: 1px;
	top: auto;
	left: unset;
	background: transparent;
	line-height: 26px;
	color: #7A7A7A;
	font-size: 11px;
	font-size: 1.3rem;
	z-index: 20;
	text-align: left;
}

@media screen and (min-width: 640px) {
	.top-page-slider-box,
  .top-page-slider-box .slick-list,
  .top-page-slider-box .slick-slide,
  .top-page-slider-box .slick-track,
  .top-page-slider-box .slider {
    height: 470px;
  }
}

@media screen and (max-width: 1024px){
	.top-article-title {
    font-size: 1.7rem;
     height: auto;
     padding: 0;
	}
	.top-article-title-wrap {
		height: 70px;
	}
	.top-page-slider-box .top-article-list-item .label-gray {
		position: relative;
	}
	.top-category-slider-box .cont .img-wrap__title .label-gray {
		position: relative;
		bottom: 3px;
	}
	.top-article-list-item-box{
		height: auto!important;
		margin-top: -8px;
	}
}

/* トップ スマホオーサー枠を横一列に */
@media screen and (max-width: 1024px) {
	.authorlist .inr{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding:0 2%;
		width: 100%;
		box-sizing: border-box;
	}
	.authorlist .inr object{
		width: auto;
		display: block!important;
		margin-bottom: 2%;
	}
	.authorlist .inr object:not(:nth-child(-n+5)){
		margin-bottom: 2%;
	}
	.authorlist .inr object:not(:nth-child(6n)){
		margin-right: 2%;
	}
	.authorlist .inr object span{
		width: auto;
		height: auto;
	}
	.authorlist .inr object span img{
		/*width: 60px;
		height: 60px;*/
		width: calc(130vw / 10.24 );
		height: calc(130vw / 10.24 );
		max-width: 100%;
	}
}

@media screen and (max-width: 720px) {
	.authorlist .caption {
			font-size: 1.8rem;
	}
}

/* 各カテゴリートップ */
.categoriesCover .inr .cont,.categoriesCover .inr .cont .top-article-title{
    vertical-align: top;
}
.categoriesCover .inr .cont a { padding-bottom: 0%; }

.container .caTitle {
	margin: 10px auto 10px;
}

.img-wrap .img-wrap__inner {
  width: 100%;
  padding-top: 56%;
  position: relative;
  height: 100%;
}

.img-wrap .img-wrap__inner > iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
}

.top-category-slider-box .cont .img-wrap__title{
	position: relative;
}

@media screen and (min-width: 640px) {
	/* PC CATEGORY TOP:スライダー枠下にタイトル文とSponsoredを表示 */
	.top-category-slider-box,
  .top-category-slider-box .slick-list,
  .top-category-slider-box .slick-slide,
  .top-category-slider-box .slick-track,
  .top-category-slider-box .slider {
    min-height: 320px;
  }
  .img-wrap__title{
	  vertical-align: top;
  	color: #000000;
		line-height: 28px;
		padding: 10px 20px;
		background: linear-gradient(
			180deg,
			rgba(254, 254, 255, 1),
			rgba(235, 235, 250, 1)
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr= "#00000000",endColorstr="#8c000000",GradientType=0);
		border-left: 1px solid #ebebf2;
  }
}

@media screen and (max-width: 1024px){
	.container .caTitle {
		margin: 10px;
	}
	.img-wrap__title{
		height: 30px;
	}
}

@media screen and (max-width: 640px){
  .img-wrap__title{
	  vertical-align: top;
  	color: #000000;
		padding: 10px 10px 30px 10px;
		background: linear-gradient(
			180deg,
			rgba(254, 254, 255, 1),
			rgba(235, 235, 250, 1)
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr= "#00000000",endColorstr="#8c000000",GradientType=0);
		border-left: 2px solid #ebebf2;
  }
}


/* トップページコンテンツ内動画 */
.movie-wrap{
	position: relative;
}
.movie-wrap > iframe{
	width: 100%;
	height: 100%;
	position: absolute;
	top:0;
	left:0;
	z-index: 1;
}

/* トップのスライド以外のスポンサー表示 */
.label-sponsored{
	position: absolute;
	bottom: -2em;
	font-size: 1.1rem;
}

.article-list-title.has-label-sponsored{
	margin-bottom: 30px;
}
