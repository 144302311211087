@charset "utf-8";

/* PC ----------------------------------------------------------------------------*/
/*----------------------------------------------------
 base
----------------------------------------------------*/
html {
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    font-size: 62.5%; /* 1rem=10px */
}
body {
    font-family: YuGothic,'Yu Gothic',"游ゴシック体","游ゴシック","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    background: #fff;
    color: #454442;
    word-wrap: break-word;
    min-width: 1200px;
}
.clear {
    clear: both;
}
.clearfix {
    *zoom: 1;
}
.clearfix:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
}
a {
    color: #454442;
    text-decoration: none;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}
a:hover {
    color: #7baf27;
    text-decoration: none;
}
img {
    max-width: 100%;
    vertical-align: bottom;
    -ms-interpolation-mode: bicubic;
}
a:hover img.trans,
a.trans:hover img,
a.trans:hover {
    cursor: pointer;
    opacity: 0.7;
    filter: alpha(opacity=70);
}
.link-blue,
.text-blue {
    color: #0044cc !important;
}
a:hover .link-blue {
    text-decoration: underline;
}
.clear-text {
    text-indent:100%;
    white-space:nowrap;
    overflow:hidden;
}

/* helper */
.centering-content {
    text-align: center;
}
.centering-content > div,
.centering-content > iframe,
.centering-content > span,
.centering-content > ins,
.centering-content > img {
    display: inline-block;
    text-align: left;
}
.text-color-default {
    color: #454442;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-normal {
    font-weight: normal;
}
.text-bold {
    font-weight: bold;
}
.left {
    float: left;
}
.right {
    float: right;
}
.hide {
    display: none !important;
}
.full-width {
    width: 100%;
}
.display-block {
    display: block;
}
.display-inline {
    display: inline;
}
.display-inline-block {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}
.list-disc {
    list-style: disc;
    padding-left: 1.5em;
}
.list-square {
    list-style: square;
    padding-left: 1.5em;
}
.list-decimal {
    list-style: decimal;
    padding-left: 1.5em;
}
.list-indent {
    margin-left: 0;
}
.list-indent li {
    padding-left: 1em;
    list-style-type: none;
    text-indent: -1em;
}
.has-grid {
    letter-spacing: -0.4em;
}
.has-grid > .has-grid-item {
    display: inline-block;
    letter-spacing: normal;
    vertical-align: middle;
}
.has-grid-item-article-list {
    vertical-align: top !important;
}
.has-table {
    display: block;
    display: table;
}
.has-table > .has-table-item {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    display: table-cell;
    vertical-align: middle;
}
/* margin */
.mb0 { margin-bottom: 0 !important;}
.mb10 { margin-bottom: 10px !important;}
.mb20 { margin-bottom: 20px !important;}

/*----------------------------------------------------
 common
----------------------------------------------------*/
.icon-arw-down {
    display: inline-block;
    vertical-align: 3px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    margin-left: 12px;
}
.is-pc {
    display: block;
}
.is-mobile {
    display: none;
}

/*----------------------------------------------------
 header
----------------------------------------------------*/
.header {
    width: 1140px;
    margin: 0 auto;
}
.header-inner {
    position: relative;
    text-align: left;
    padding: 25px 0 10px 0;
}
.header-menu-sp {
    display: none;
}
/* search */
.header-search-pc {
    position: absolute;
    top: 55px;
    right: 0;
}
.header-search-pc .search-form {
    position: relative;
    border: 1px solid #dedede;
    width: 270px;
    text-align: left;
    padding-left: 7px;
}
.header-search-pc .search-form-input {
    display: inline-block;
    border: none;
    box-sizing: border-box;
    font-size: 13px;
    font-size: 1.3rem;
    width: 240px;
    height: 30px;
    line-height: 30px;
    outline: none;
    -webkit-appearance: none;
}
.header-search-pc .search-form-submit {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    background: #fafafa;
    border-top: none;
    border-bottom: none;
    border-right: none;
    border-left: 1px solid #dedede;
    width: 30px;
    height: 30px;
    outline: none;
}
.header-menu-column {
    position: absolute;
    top: 52px;
    right: 400px;
    font-size: 2.0rem;
    font-weight: bold;
}
.header-menu-column a {
    color: #808080;
}
.icon-search {
    display: block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-search.png) no-repeat 0 0;
    background-size: 14px 14px;
    width: 14px;
    height: 14px;
    margin: 0 auto;
}

@media screen and (min-width: 1025px) {
    img.pc-logo-banner {
        margin-left:20px;
        width: 268px;
    }
    img.pc-logo {
        margin-bottom: 10px;
    }
    img.sp-logo-banner {
        display: none;
    }
    img.sp-logo {
        display: none;
    }
}
.accordion-container .accordion-title {
    position: absolute;
    top: 42px;
    font-size: 2.0rem;
    font-weight: bold;
    color: #808080;
    right: 550px;
    padding: 10px 30px 10px 10px;
}
.accordion-container .accordion-title::after {
    content: "";
    position: absolute;
    top: 22px;
    left: 75px;
    font-size: 1.6rem;
    border: 8px solid transparent;
    border-top-color: #808080;
}
.accordion-container .accordion-title.open::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 75px;
    font-size: 1.8rem;
    border: 8px solid transparent;
    border-bottom-color: #808080;
}
.accordion-content {
    top: 90px;
    z-index: 20;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    width: 200px;
    right: 37%;
    text-align: center;
    font-size: 2.0rem;
    background-color: #FFFFFF;
    position: absolute;
    border-radius: 8px;
}
.accordion-container {
    width: 300px;
    margin: 0 auto;
}
li.list {
    height: 38px;
    box-sizing: border-box;
    border-bottom: 1px solid #dcdcdc;
}
li.list:last-child {
    border-bottom: none;
}
li.list:hover {
    background-color: #f5f5dc;
    color: #808000;
}
.list a {
    vertical-align: middle;
    font-weight: bold;
}

/*----------------------------------------------------
 navi
----------------------------------------------------*/
.swipe-list-tab {
    margin: 0 auto 10px;
    background: #7baf27;
    white-space: nowrap;
}
.swipe-list-tab-list {
    text-align: center;
}
.swipe-list-tab-item {
    display: inline-block;
    height: 35px;
    margin: 0 15px;
}
.swipe-list-tab-item a {
    display: block;
    color:#fff;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 5px 0;
}
.swipe-list-tab-item a img {
    height: 27px;
}
.swipe-list-tab-item a:hover span {
    border-bottom: 5px solid #fff;
    padding-bottom: 5px;
    color: #fff;
}
.swipe-list-tab-item.current a span {
    border-bottom: 5px solid #fff;
    padding-bottom: 5px;
}
hr.head {
    display: block;
    height: 3px;
    border-bottom: 1px solid #ccc;
    margin: 0.7em;
}
.navi-quiz-list {
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 20;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    width: 145px;
}  
/*----------------------------------------------------
 media
----------------------------------------------------*/
.media {
    width: 870px;
    margin: 0 auto;
    text-align: center;
}
.media-title {
    text-align: center;
    padding: 30px 0 20px;
}
.media-list {
    display: table;
    width: 92%;
    text-align: left;
    margin: 10px auto 50px;
}
.media-list-item {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 200px;
    padding: 20px 35px;
    box-sizing: border-box;
}
.media-list-item img {
    max-width: 130px;
    max-height: 50px;
    vertical-align: middle;
}

/*----------------------------------------------------
 footer
----------------------------------------------------*/
.footer-wrap {
    background: #f0f0f0;
    padding: 30px 0;
}
.footer {
    width: 1200px;
    margin: 0 auto;
}
.footer a:hover {
    color: #888;
}
.footer-inner {
    display: table;
    table-layout: fixed;
    width:60%;
}
.footer-inner .inner-cont {
    display: table-cell;
    vertical-align: top;
}
.footer-inner .inner-cont.sns {
    width: 480px;
    padding: 0 0 0 70px;
    box-sizing: border-box;
    position: relative;
    top:-7px;
}
.footer-inner .inner-cont.sns .fb-page {
    margin-bottom: 15px;
}
.footer-inner .inner-cont.sns .title {
    font-size: 2.2rem;
    padding: 0 0 20px;
}
.footer-inner .inner-cont.sns dl {
    padding: 0 0 10px;
}
.footer-inner .inner-cont.sns dl:after {
    content: "";
    display: block;
    clear: both;
}
.footer-inner .inner-cont.sns dt {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 0 0 5px;
}
.footer-inner .inner-cont.sns dd {
    float: left;
    width: 50px;
    margin: 0 15px 0 0;
    border-radius: 1000px;
}
.footer-inner .inner-cont.sns dd a {
    border-radius: 1000px;
}
.footer-inner .inner-cont.sns dd a img {
    border-radius: 1000px;
}
.footer-inner .inner-cont.sns dd span {
    display: none;
}
.footer-inner .inner-cont.outline {
    border-left: 1px solid #ccc;
    padding-left: 40px;
}
.sns-list-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
}
.btn-rss {
    display: block;
    vertical-align: top;
    background: #ff8c00;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    font-size: 11px;
    font-size: 1.1rem;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
}
.btn-rss:hover {
    color: #fff !important;
}
.icon-rss {
    display: inline-block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-rss.png) no-repeat 0 0;
    background-size: 10px 10px;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.footer-inner .inner-cont .lead {
    font-size: 14px;
    font-size: 1.4rem;
}
.footer-inner .inner-cont .lead img {
    width: 200px;
}

.footer-inner .inner-cont .lead-text {
    padding: 30px 0;
}

.footer-inner .inner-cont .lead dd.footer-sns {
    display: inline-block;
    width: 5%;
    padding: 0 0 0 15px;
}

.footer-inner .inner-cont .category {
    display: table-cell;
}
.footer-inner .inner-cont .outline {
    display: table-cell;
    border-left: 1px solid #ccc;
    width: 260px;
    padding: 0 0 50px 40px;
}
.footer-title {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
}
.footer-nav-list {
    letter-spacing: -0.4em;
}
.footer-nav-list-item {
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: normal;
    width: 50%;
}
.outline-list-item {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
}
.copy {
    text-align: left;
    color: #999;
    font-size: 12px;
    font-size: 1.2rem;
}
.aalogo img {
    margin-top: 5px;
    max-width: 75px;
}

/*----------------------------------------------------
 ad
----------------------------------------------------*/
.top-banner {
    margin-bottom: 5px;
    text-align: center;
}
.ad-box {
    margin-bottom: 30px;
}
div#div-gpt-ad-1554265965472-0 {
    margin-bottom: 20px;
}
.ad-box-pc {
    width: 100%;
    margin: 0 auto 30px;
}
.ad-box-pc-no-margin {
    width: 100%;
}
.ad-box-pc .column2 {
    width: 50%;
    text-align: center;
}
.ad-box-sp {
    display: none;
}
.ad-box-sp-no-margin {
    display: none;
}
/* 誘導枠 */
.pickup {
    text-align: center;
}
.pickup-item-box {
    position: relative;
    display: inline-block;
}
.pickup-title-wrap {
    position: absolute;
    bottom: 0;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.55) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#8c000000',GradientType=0 ); /* IE6-9 */
    display: block;
    width: 94%;
    padding: 30px 3% 15px;
    color: #fff;
}
.pickup-title {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
}
/* メインカラム */
.main .pickup-item-box {
    max-width: 600px;
    max-height: 500px;
}
.main .pickup-item-box img{
    max-width: inherit;
    max-height: inherit;
}
/* 右カラム */
.sub-cont .pickup-item-box {
    max-width: 300px;
}
.sub-cont .pickup-title {
    font-size: 16px;
    font-size: 1.6rem;
}

/*----------------------------------------------------
 common
----------------------------------------------------*/
.container {
    margin: 0 auto;
}
.content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}
.main {
    float: left;
    width: 870px;
}
.pagetop {
    display: none;
}

/*----------------------------------------------------
 右カラム
----------------------------------------------------*/
.sub-cont-wrap {
    position: relative;
    display: block;
    float: right;
    width: 300px;
}
.sub-cont-wrap-inner {
    width: inherit;
    background: #fff;
}
.sub-cont {
    float: right;
    width: 300px;
    margin-bottom: 30px;
}
.sub-cont-title {
    border-bottom: 2px solid #454442;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    line-height: 42px;
    margin-bottom: 18px;
}

.authorlist { padding: 0 0 20px; max-width: 1350px; margin: 0 auto; }
.authorlist > a { display: block; }
.authorlist .caption { color:#000; font-size: 180%; text-align: center; padding: 0 0 15px; font-family: メイリオ,Meiryo,YuGothic,Yu Gothic,游ゴシック体,游ゴシック,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,Osaka,ＭＳ\ Ｐゴシック,MS PGothic,sans-serif; }
.authorlist .inr { display: table; width: 100%; margin: 0 auto; table-layout: fixed; }
.authorlist .inr object { display: table-cell; text-align: center; vertical-align: top; }
.authorlist .inr object span { display: inline-block; width: 90px; height: 90px; overflow: hidden; border-radius:45px;transition: all .5s ease; }
.authorlist .inr object span img { max-width: 5000px; object-fit: cover; height: 100px; -moz-transition: -moz-transform 0.5s linear; -webkit-transition: -webkit-transform 0.5s linear; -o-transition: -o-transform 0.5s linear; -ms-transition: -ms-transform 0.5s linear; transition: transform 0.5s linear; }
.videolist { padding: 20px 10px; margin: 0 0 20px; }
.videolist .caption { color:#7baf27; font-size: 180%; font-weight: bold; text-align: center; font-family: メイリオ,Meiryo,YuGothic,Yu Gothic,游ゴシック体,游ゴシック,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,Osaka,ＭＳ\ Ｐゴシック,MS PGothic,sans-serif; }
.videolist { text-align: center; background: #ffffef; }
.videolist .inr { display: table; width: 100%; max-width: 1920px; display: table; margin: 0 auto; table-layout: fixed; }
.videolist .cont { display: table-cell; margin: 0 8px; position: relative; vertical-align: bottom; }
.videolist .cont a { display: block; margin: 0 10px; transition: all .25s ease; }
.videolist .cont a:hover { opacity: 0.75; }
.videolist .cont dl { width: 100%; height: auto; padding: 0 0 56%; position: relative; overflow: hidden; }
.videolist .cont dl.chilltv { border: 1px solid #E7115B; box-sizing: border-box; }
.videolist .cont dl dd { position: absolute; top:0; left: 0; z-index: 10; width: 100%; height: 0; padding: 0 0 56%; }
.videolist .cont figure figcaption img { max-width: 20%; margin-bottom: 3px; }
.videolist .cont dl dd img { display: none; }
.videolist .cont dl dt { position: absolute; bottom:0; left: 0; width: 100%; font-size: 120%; padding: 10px; box-sizing: border-box; font-weight: bold; line-height: 1.2; z-index: 20; text-align: left; color:#fff; background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.55)); filter:progid:DXImageTransform.Microsoft.gradient(startColorstr= "#00000000",endColorstr="#8c000000",GradientType=0) }
.videolist ul { width: 100%; clear: both; padding: 20px 0 0; text-align: center; }
.videolist ul a { display: inline-block; padding: 10px; min-width: 400px; background: #464543; color: #fff; font-weight: 700; transition: all .25s ease; position: relative; }
.videolist ul a:hover { opacity: 0.75; }
.videolist ul a:after { content: ""; display: inline-block; position: absolute; top:50%; right: 15px; margin: -6px 0 0; width: 10px; height: 10px; border: 1px solid; border-color: #ffffff #ffffff transparent transparent; transform: rotate(45deg); }

/*----------------------------------------------------
 Ranking
----------------------------------------------------*/
/* tab */
.rank-tab-list {
    text-align: center;
    margin-bottom: 20px;
}
.rank-tab-list-item {
    display: inline-block;
    margin-right: 50px;
}
.rank-tab-list-item:last-child {
    margin-right: 0;
}
.rank-tab-list-item-box {
    display: block;
    color: #454442;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 5px;
}
.rank-tab-list-item-box:hover {
    border-bottom: 5px solid #7baf27;
    color: #454442;
    font-weight: bold;
    text-decoration: none;
}
.rank-tab-list-item-box.here {
    border-bottom: 5px solid #7baf27;
    font-weight: bold;
}

/* list */
.rank-list-item {
    margin-bottom: 15px;
}
.rank-list-item-box-l {
    position: relative;
    width: 300px;
    height: 169px;
    display: block;
    margin: 0 0 5px;
}
.rank-list-item-box-r {
    width: auto;
    margin:0;
}
.icon-circle {
    position: absolute;
    top: 5px;
    left: 5px;
    display: block;
    background: #fff;
    border: 1px solid #7baf27;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    color: #7baf27;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    width: 26px;
    height: 26px;
    line-height: 25px;
    text-align: center;
}
.icon-circle.top3 {
    background: #7baf27;
    color: #fff;
}

/*----------------------------------------------------
 Ranking（SPページのみ）
----------------------------------------------------*/
.ranking-sp {
    width: 94%;
    margin: 0 3%;
}
.ranking-sp .ranking-title {
    border-bottom: 2px solid #454442;
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 42px;
    margin-bottom: 20px;
}
/* tab */
.ranking-sp .rank-tab-list {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 15px;
}
.ranking-sp .rank-tab-list-item {
    display: table-cell;
    margin-right: 0;
}
.ranking-sp .rank-tab-list-item:last-child {
    margin-right: 0;
}
.ranking-sp .rank-tab-list-item-box {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 3px 10px;
}
.ranking-sp .rank-tab-list-item-box:hover {
    border-bottom: 3px solid #7baf27;
    text-decoration: none;
}
.ranking-sp .rank-tab-list-item-box.here {
    border-bottom: 3px solid #7baf27;
}
/* list */
.ranking-sp .rank-list-item {
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;
}
.ranking-sp .rank-list-item-box-l {
    width: 30%;
    height: 0;
    padding-bottom: 22%;
}
.ranking-sp .rank-list-item-box-r {
    width: 67%;
    margin-left: 3%;
}
.ranking-sp .icon-circle {
    font-size: 1.3rem;
}

/*----------------------------------------------------
 Tag
----------------------------------------------------*/
.tag-list-item {
    margin: 0 10px 15px 0;
}
.tag-list-item-box {
    display: block;
    border: 1px solid #dedede;
    font-size: 14px;
    font-size: 1.4rem;
    padding: 5px 10px;
}
.tag-list-item-box:hover {
    background: #f0f0f0;
    color: #454442;
}

/*----------------------------------------------------
 list
----------------------------------------------------*/
.article-list-item {
    display: inline-block;
    width: 276px;
    margin-right: 20px;
}
.article-list-item:nth-child(3n) {
    margin-right: 0;
}
.article-list-item-box {
    position: relative;
    display: block;
}
.article-thum {
    position: relative;
    width: 276px;
    height: 155px;
    margin-bottom: 7px;
}
.article-thum img {
    vertical-align: top;
}
.article-list-title {
    margin-bottom: 30px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
}
/* label */
.label-gray {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: #ababab;
    width: 74px;
    height: 26px;
    line-height: 26px;
    color: #fff;
    font-size: 13px;
    font-size: 1.3rem;
    text-align: center;
    z-index: 20;
}
/* label（右カラム ランキング） */
.ranking .label-gray {
    top: initial;
    left: initial;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    font-size: 1rem;
}
/* movie */
.icon-video {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 66px;
    height: 66px;
    margin-top: -33px;
    margin-left: -33px;
}
.icon-video:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-width: 18px 0 18px 32px;
    border-color: transparent transparent transparent #fff;
    margin-top: -17px;
    margin-left: -12px;
}
/* movie（右カラム） */
.sub-cont .icon-video {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
}
.sub-cont .icon-video:before {
    border-width: 8px 0 8px 13px;
    margin-top: -8px;
    margin-left: -5px;
}
/* imgLiquid */
.img-wrap {
    visibility: hidden;
}
/* 次を見る */
.readmore-unit {
    text-align: center;
    margin-bottom: 20px;
    padding: 0 10px;
}
.readmore-unit .btn-readmore {
    position: relative;
    display: block;
    background: #7BAF27;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
    width: 100%;
    height: 55px;
    line-height: 55px;
    margin: 0 auto;
}

.paginate-unit {
    width: 100%;
    margin-bottom: 75px;
}

/* PREV */
.paginate .prev {
    float: left;
    position: relative;
    display: block;
    background: #7BAF27;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
    width: 30%;
    height: 55px;
    line-height: 55px;
    margin-right: auto;
}

/* NEXT */
.paginate .next {
    float: right;
    position: relative;
    display: block;
    background: #7BAF27;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
    width: 30%;
    height: 55px;
    line-height: 55px;
    margin-left: auto;
}

.paginate-article-unit {
    width: 100%;
}

.paginate-article-unit a:hover {
    text-decoration: none !important;
}

/* NEXT */
.paginate-article-unit .next {
    position: relative;
    display: block;
    background: #7BAF27;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
    width: 45%;
    height: 55px;
    line-height: 55px;
    margin: 10px auto auto;
}

/* PREV */
.paginate-article .prev {
    float: left;
    position: relative;
    display: block;
    background: #c00;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
    width: 45%;
    height: 55px;
    line-height: 55px;
    margin-right: auto;
}

/* NEXT */
.paginate-article .next {
    float: right;
    position: relative;
    display: block;
    background: #c00;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
    width: 45%;
    height: 55px;
    line-height: 55px;
    margin-left: auto;
}

/*----------------------------------------------------
 top
----------------------------------------------------*/
/* slider */
.main-top {
    margin: 0 auto 20px;
    overflow: hidden;
    clear: both;
    position: relative;
}
.top-article-list {
    display: inline-block;
    letter-spacing: normal;
}
.top-article-list-item-box {
    position: relative;
    display: block;
    width: 480px;
    height: 360px;
    overflow: hidden;
}
.top-article-title-wrap {
    position: absolute;
    bottom: 0;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.55) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.55));
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr= "#00000000",endColorstr="#8c000000",GradientType=0);
    display: block;
    padding: 30px 10px 10px;
    color: #fff;
}
.main-top .top-article-title-wrap {
    width: 460px;
}
.list-top .top-article-title-wrap {
    width: 560px;
}
.top-article-title {
    vertical-align: bottom;
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    height: 70px;
}

.caTitle { padding: 0 10px 0 7px; box-sizing: border-box; margin: 0 auto 10px; max-width: 1440px; border-left: 14px solid #eddc44; font-size: 3rem; font-weight: 700; line-height: 1; }
.slider {
    max-width: 1920px;
    margin: 0 auto;
    letter-spacing: -.4em;
    position: relative;
}
top-article-list sys-top-article-list slick-slide slick-cloned
top-article-list sys-top-article-list slick-slide slick-active

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.top-article-title-wrap { opacity: 0 !important; }
.slick-current .top-article-title-wrap { opacity: 1 !important; }
.catslide .slick-current .top-article-title-wrap { opacity: 0 !important; }
.catslide .slick-current + .top-article-list .top-article-title-wrap { opacity: 1 !important; }
.slick-slide {
    display: none;
    float: left;
    height: 56%;
    min-height: 1px;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-list {
    overflow: hidden;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-track {
    position: relative;
}
.slick-arrow {
    position: absolute;
    top:50%;
    margin: -40px 0 0;
    display:block;
    width: 40px;
    height: 40px;
    text-align: center;
    background: rgba(0,0,0,0.75);
    cursor: pointer;
    transition: all .25s ease;
    z-index: 999;
    border: none;
    outline:none;
    font-size: 0;
    line-height: 0;
}
.slick-prev { left: 0; margin: -30px 0 0; }
.slick-next { right: 0; margin: -30px 0 0; }
.slick-arrow:hover { width: 36px; }

.slick-next:after {
    content: '';
    width: 6px;
    height: 6px;
    border: 0px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -6px;
}
.slick-prev:after {
    content: '';
    width: 6px;
    height: 6px;
    border: 0px;
    border-bottom: solid 2px #fff;
    border-left: solid 2px #fff;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -3px;
}

/*----------------------------------------------------
 article
----------------------------------------------------*/
.article-wrap {
    border-bottom: 1px solid #dedede;
    margin-bottom: 50px;
}
/* 記事ヘッダー */
.article-heading {
    margin-bottom: 14px;
}
.citrus-h1 {
    color: #000;
    font-size: 30px;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.3;
    margin: 0px 0 10px 0;
}
.article-heading-aside {
    position: relative;
}
.article-bottom-aside {
    margin-top: -10px;
    margin-bottom: 10px;
}
.label-wrap {
    position: absolute;
    left: 0;
    bottom: 3px;
    display: table;
}
.label-wrap .label-gray {
    position: static;
    display: inline-block;
    vertical-align: top;
    width: inherit;
    padding: 0 14px;
    margin-right: 10px;
    white-space: nowrap;
}
.label-wrap .label-gray:last-child {
    margin-right: 0;
}
.article-heading-aside .right{
    text-align: right;
}
.author {
    font-size: 15px;
    font-size: 1.5rem;
    margin: 10px 0 5px 0;
}
.author a {
    color: #0044cc;
}
.author a:hover {
    text-decoration: underline;
}
.author img{
    height: 27px;
}
.author-anchor {
    color: #0044cc;
    display: flex;
    webkit-align-items: center;
    align-items: center;
}
.author-anchor:hover {
    color: #0044cc;
    text-decoration: underline;
}
.author-wrap-flex {
    display: flex;
    webkit-align-items: center;
    align-items: center;
}
.author-image{
    height: 50px;
    width: 50px;
    border-radius:50%;
    -ms-border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    background-size: cover;
    border:1px solid #e6e6e6;
    vertical-align: bottom;
    margin-left: 10px;
}
.article-date {
    color: #ababab;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
}
/* 記事 */
.article-content {
    color: #000;
    font-size: 1.6rem;
    line-height: 1.7;
    margin-bottom: 50px;
}
.article-content a {
    color: #0044cc;
}
.article-content a.add_heading_at_beginning:before {
    content: "";
    display: inline-block;
    margin-left: 2px;
    margin-right: 8px;
    margin-bottom: 4px;
    width: 5px;
    height: 5px;
    border-bottom: 1px solid #04c;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(0, 68, 204);
    border-right: 1px solid #04c;
    transform: rotateZ(-45deg);
}
.article-content a:hover {
    text-decoration: underline;
}
.article-content h2 {
    color: #000;
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px;
}
.article-content h3 {
    color: #000;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px;
}
.article-content h4 {
    color: #ababab;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2;
    margin: 0 auto;
}

/* 記事画像 */
.article-content > img {
    display: block;
    margin: 10px 0;
}
.article-img-box01 {
    text-align: center;
    margin-bottom: 7px;
}
.article-img-box02 {
    display: inline-block;
    text-align: center;
}
.article-img-box02.right {
    margin-left: 10px;
}
.article-img-box02.left {
    margin-right: 10px;
}
.article-img-wrap {
    width: 100%;
}
.article-img {
    width: auto;
}
.article-img-cap {
    color: #ababab;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.3;
    padding: 14px 0;
    margin: 0 auto;
}

.citrus-center-image {
    margin-bottom: 7px;
}
.citrus-center-image .citrus-image{
    margin: 0 auto;
    text-align: center;
}
.citrus-right-image {
    float: right;
    max-width: 450px;
    margin-left: 10px;
}
.citrus-left-image {
    float: left;
    max-width: 450px;
    margin-right: 15px;
}
.citrus-image {
    height: auto;
}
.citrus-caption {
    color: #ababab;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.3;
    padding: 14px 0;
    margin: 0 auto;
}
.citrus-right-image .citrus-caption,
.citrus-left-image .citrus-caption {
    color: #ababab;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.3;
    max-width: 450px;
    padding: 14px 0;
    margin: 0 auto;
}

/* 記事動画 */
.article-video-wrap {
    width: 100%;
    margin-bottom: 30px;
}
.article-video-wrap iframe {
    display: block;
    margin: 0 auto;
}
.article-video-wrap video {
    width: 100%;
}
.fb-video {
    margin-bottom: 30px;
}
.spreader {
    text-align: center;
    margin-bottom: 30px;
}
.spreader-inner {
    display: inline-block;
    text-align: left;
}
/* 続きを読む */
.article-body {
    overflow: hidden;
    position: relative;
    height: 1500px;
}
.article-body.videosource {
    height: 800px;
}
.article-body:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 250px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(30%,rgba(255,255,255,0.8)), color-stop(60%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 30%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 30%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);
    pointer-events: none;
}
.article-body.is-viewable {
    overflow: visible;
    height: auto;
}
.article-body.is-viewable:after {
    display: none;
}
.article-readmore {
    position: absolute;
    right: 0;
    bottom: 20px;
    left: 0;
    z-index: 20;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 8px;
    padding-bottom: 20px;
}
.is-viewable .article-readmore {
    display: none;
}
.article-readmore-btn {
    position: relative;
    display: block;
    background: #ffc306;
    color: #fff;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    width: 600px;
    height: 54px;
    line-height: 54px;
    margin: 0 auto;
}
/* 引用 */
.article blockquote {
    display: block;
    position: relative;
    background: #eee;
    color: #454442;
    line-height: 1.5;
    padding: 40px 70px;
    margin-bottom: 30px;
}
.article blockquote:before {
    position: absolute;
    top: 15px;
    left: 20px;
    content: "";
    display: inline-block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-quote.png) no-repeat 0 0;
    background-size: 49px 37px;
    width: 49px;
    height: 37px;
}
.article blockquote:after {
    position: absolute;
    bottom: 15px;
    right: 20px;
    content: "";
    display: inline-block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-quote.png) no-repeat 0 0;
    background-size: 49px 37px;
    width: 49px;
    height: 37px;
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
/* リスト */
.article-content ul {
    list-style-type: disc;
    margin-left: 28px;
}
.article-content ol {
    list-style-type: decimal;
    margin-left: 28px;
}
/* SNS */
.article-sns {
    width: 870px;
    margin-bottom: 30px;
    letter-spacing: -0.4em;
    text-align: center;
}
.fb-good {
    display: inline-block;
    vertical-align: top;
    background: #eee;
    padding: 10px 0;
    color: #000;
    width: 410px;
    height: 74px;
    text-align: center;
    margin-right: 25px;
    letter-spacing: normal;
}
.fb-good .title {
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: bold;
}
.fb-good .text {
    font-size: 12px;
    font-size: 1.2rem;
}
.fb-good .fb-like {
    z-index: 10;
}

.fb-good .twitter-follow {
    z-index: 10;
    height: 20px;
    display: inline-block;
    vertical-align: bottom;
}

.fbiine .fb-like {
    position: relative;
}

.sns-list {
    display: inline-block;
    width: 435px;
}
.sns-list div{
    display: inline-block;
}
.sns-list .fb {
    margin-right: 25px;
}
.sns-list .fb a {
    display: block;
    background: #3b5998;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    width: 145px;
    height: 64px;
    padding: 15px 30px;
}
.sns-list .twi a {
    display: block;
    background: #55acee;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    width: 145px;
    height: 64px;
    padding: 15px 30px;
}
.sns-list .line {
    display: none;
}
.icon-fb {
    display: block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-fb.png) no-repeat center center;
    background-size: 33px 33px;
    width: 33px;
    height: 33px;
    margin: 0 auto 10px;
}
.icon-twi {
    display: block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-twi.png) no-repeat center center;
    background-size: 31px 25px;
    width: 33px;
    height: 33px;
    margin: 3px auto 7px;
}
/* 媒体紹介 */
.article-media {
    border: 1.8px solid #dedede;
    box-sizing: border-box;
    font-size: 14px;
    font-size: 1.4rem;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 30px;
}
.article-media-img-wrap {
    width: 185px;
    text-align: center;
}
.article-media-img-wrap img {
    width: 100px;
}
.article-media-img-wrap .author_img {
    height: 100px;
    width: 100px;
    border-radius:50%;
    -ms-border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    background-size: cover;
    border:1px solid #e6e6e6;
}
.article-media-detail {
    padding: 0 15px;
}
.article-media-detail .title {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px;
}
.article-media-detail .title {
    font-weight: bold;
}
.article-media-detail .text {
    color: #7baf27;
    font-weight: bold;
}
.article-media-link {
    float: right;
    color: #0044cc;
    margin: 5px 0 0;
    letter-spacing: normal;
}
.article-media-link:hover {
    color: #0044cc;
    text-decoration: underline;
}
/* 誘導枠 */
.induction-header {
    font-size: 15px;
    color: #c00;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.induction-header:before, .induction-header:after {
    content: "";
    flex-grow: 1;
    border-top: 2px dotted #c00;
    display: block;
}

.induction-article {
    border: 1.8px solid #dedede;
    box-sizing: border-box;
    font-size: 14px;
    font-size: 1.4rem;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 30px;
}
.induction-article-img-wrap {
    width: 185px;
    text-align: center;
}
.induction-article-img-wrap2 {
    text-align: center;
    padding: 1%;
}
.induction-article-img-wrap img {
    width: 150px;
}
.induction-article-img-wrap .author_img {
    height: 100px;
    width: 100px;
    border-radius:50%;
    -ms-border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    background-size: cover;
    border:1px solid #e6e6e6;
}
.induction-article-detail {
    padding: 0 15px;
}
.induction-article-detail .title {
    font-size: 16px;
    font-size: 2.0rem;
    font-weight: bold;
    margin-bottom: 10px;
}
.induction-article-detail .title {
    font-weight: bold;
}
.induction-article-detail .text {
    color: #7baf27;
    font-weight: bold;
}
.induction-article-link {
    float: right;
    color: #0044cc;
    margin: 5px 0 0;
    letter-spacing: normal;
}
.induction-article-link:hover {
    color: #0044cc;
    text-decoration: underline;
}

/* 関連キーワード */
.related {
    margin-bottom: 0px;
}
.related-title {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 14px;
}
/* 矢印 */
.icon-arw-blue {
    position: relative;
    display: inline-block;
    vertical-align: -2px;
    width: 16px;
    height: 16px;
    margin-right: 7px;
}
.icon-arw-blue:before,
.icon-arw-blue:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    margin: auto;
    vertical-align: middle;
}
.icon-arw-blue:before{
    background: #0044cc;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}
.icon-arw-blue:after{
    left: 6px;
    border: 4px solid transparent;
    border-left: 6px solid #fff;
    box-sizing: border-box;
    width: 5px;
    height: 5px;
}
/* 媒体社一覧 */
.article-content.media-info {
    font-size: 12px;
    font-size: 1.2rem;
    text-align: right;
}
.article-content.media-info p {
    margin-bottom: 3px;
}

/*----------------------------------------------------
 list - category
----------------------------------------------------*/
.list-top {
    background: #f0f0f0;
    padding: 30px 0;
    margin-bottom: 30px;
}
.list-top-title {
    font-size: 30px;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 15px;
}
.list-top-inner .top-article-list-item:first-child {
    margin-right: 40px;
}
.list-top-inner .top-article-list-item-box {
    width: 580px;
    height: 300px;
}

/*----------------------------------------------------
 list - media
----------------------------------------------------*/
.media-box {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    width: 870px;
    padding: 20px;
    margin-bottom: 30px;
}
.media-box-inner-left {
    margin-right: 28px;
}
.media-box-inner-left img {
    width: 150px;
    height: auto;
}
.media-box-inner-right {
    vertical-align: top;
    font-size: 14px;
    font-size: 1.4rem;
    width: 650px;
}
.media-box-inner-right a {
    color: #0044cc;
}
.media-box-inner-right a:hover {
    text-decoration: underline;
}
.media-box-title {
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 7px;
}
.media-box-position {
    color: #7baf27;
    font-weight: bold;
}
.media-box-inner-author-image{
    height: 149px;
    width: 149px;
    border-radius:50%;
    -ms-border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    background-size: cover;
    border:1px solid #e6e6e6;
}
/*----------------------------------------------------
 search
----------------------------------------------------*/
.main .gsc-control-cse {
    padding: 0;
}
.main .gsc-search-box-tools .gsc-search-box .gsc-input {
    line-height: 1.3;
}
.main .gsc-webResult .gsc-result {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #fff;
}
.main .gsc-results .gsc-cursor {
    display: block;
    text-align: center;
}
.main .gsc-results .gsc-cursor-box .gsc-cursor-page.gsc-cursor-current-page {
    background: #f1f1f1;
    color: #333;
}
.main .gsc-results .gsc-cursor-box .gsc-cursor-page {
    display: inline-block;
    margin: 0 5px;
    padding: 3px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    line-height: 19px;
    text-decoration: none;
    text-align: center;
    color: #0000cc;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    margin-bottom: 10px;
}
.main .gsc-results .gsc-cursor-box .gsc-cursor-page:hover {
    background: #f1f1f1;
    text-decoration: none;
}
.main .gsc-results td.gcsc-branding-img-noclear a {
    display: block;
    width: 51px;
}
/* search */
.search-box .search-form {
    position: relative;
    display: table;
    width: 100%;
    height: 50px;
    line-height: 50px;
}
.search-box .search-form-input {
    display: inline-block;
    vertical-align: top;
    border: 1px solid #7baf27;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-size: 15px;
    font-size: 1.5rem;
    width: 78%;
    height: 50px;
    line-height: 50px;
    outline: none;
    padding-left: 5px;
}
.search-box .search-form-submit {
    display: inline-block;
    background: #7baf27;
    border: none;
    color: #fff;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    width: 22%;
    height: 50px;
    line-height: 50px;
    outline: none;
}

/*----------------------------------------------------
 運営組織
----------------------------------------------------*/
.title-box-bar {
    background: #eddc44;
    color: #fff;
    font-size: 35px;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 35px;
    padding: 0 14px;
    margin-bottom: 20px;
}
.main-cont-inner {
    margin: 0 14px;
}
.main-cont-inner a {
    color: #0044cc;
}
.about {
    margin-bottom: 30px;
}
.about-title {
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 30px;
}
.company-info-title {
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 15px;
}
.list-table {
    border: 1px solid #dedede;
    border-bottom: 0;
    margin-bottom: 45px;
}
.list-table li {
    display: table;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
}
.list-table li .th {
    background-color: #f0f0f0;
    display: table-cell;
    vertical-align: top;
    white-space: nowrap;
    width: 36%;
    padding: 13px;
    font-weight: bold;
    border-bottom: 1px solid #dedede;
}
.list-table li .td {
    display: table-cell;
    width: 100%;
    background: white;
    padding: 13px;
    border-left: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
}
.list-table .column2-left {
    width: 380px;
}
.list-table .column2-right {
    width: 186px;
}
.list-table-transparent li.th {
    background: none !important;
    border: none !important;
    font-weight: normal !important;
    padding: 10px 0 0 0 !important;
}
.list-table-transparent li.th:first-child {
    padding-top: 0 !important;
}
.list-table-transparent li:first-child.th {
    padding-top: 0;
}
.list-table-transparent li {
    display: table;
    width: 100%;
}
.list-table-transparent li .role {
    display: table-cell;
    width: 45%;
}
.list-table-transparent li .name {
    display: table-cell;
}

/*----------------------------------------------------
 プライバシーポリシー、著作権・商標・免責事項
----------------------------------------------------*/
.privacy .main-cont-inner,
.terms .main-cont-inner{
    font-size: 16px;
    font-size: 1.6rem;
}
.content-box {
    margin-bottom: 20px;
}
.content-box p {
    margin-bottom: 4px;
}
.content-box-title {
    font-weight: bold;
    margin-bottom: 7px;
}
.content-box-list dt {
    width: 5%;
    text-align: center;
}
.content-box-list dd {
    width: 95%;
    margin-bottom: 7px;
}

/*----------------------------------------------------
 404ページ
----------------------------------------------------*/
.forbidden {
    font-size: 13px;
    font-size: 1.3rem;
    text-align: center;
    margin: 100px 0;
}
.forbidden-title {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 40px;
}
.btn-green {
    display: block;
    background: #7baf27;
    color: #fff;
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    width: 170px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0 auto 30px;
}
.btn-green span {
    font-size: 20px;
    font-size: 2rem;
}
.btn-green:hover {
    color: #fff;
}
.forbidden-text {
    margin-bottom: 15px;
}
.forbidden-text a {
    color: #0044cc;
}
.forbidden-text a:hover {
    text-decoration: underline;
}
.logo-image {
    width: 150px;
    height: auto;
    margin: 30px 0 60px;
}
.forbidden .copy{
    text-align: center;
}
.categoriesmenu { display: none; }
.caImg { text-align: center; margin: 0 auto 20px; }
.broadcastlist { width: 100%; padding: 40px 0; overflow: hidden; }
.broadcastlist li { width: 33%; text-align: center; float: left; margin: 0 0 20px; }
.broadcastlist li a { opacity: 1; transition: all .25s ease; }
.broadcastlist li a:hover { opacity: 0.75; }
.broadcastlist li img { width: 90%; margin: 0 auto; }

.categoriesCover { width: 100%; max-width: 1920px; margin: 0 auto; }
.categoriesCover .inr { display: table; width: 100%; table-layout: fixed; }
.categoriesCover .inr .cont { display: table-cell; }
.categoriesCover .inr .cont a { display: block; width: 100%; padding-bottom: 56%; position: relative; }
.categoriesCover .inr .cont a:hover { opacity: 0.75; }
.categoriesCover .inr .cont a img { display: none; }

.top-article-list-item-box { width: 100%; height: 0; padding-bottom: 56%; }
.list-top .top-article-title-wrap,
.main-top .top-article-title-wrap { width: 100%; box-sizing: border-box; }

@media screen and (min-width: 640px) {
    .main-top,
    .slider,
    .slick-list,
    .slick-track,
    .slick-slide { height: 450px; }
    .slick-slide { width: 672px; }
    .hauto { height: auto; }
}

/* SP ----------------------------------------------------------------------------*/
@media screen and (max-width:1024px) {
/*----------------------------------------------------
 common
----------------------------------------------------*/
body {
    font-size: 1.4rem;
    line-height: 1.6;
    width: 100%;
    min-width: 100%;
}
a:hover {
    color: #454442;
}
a:hover img.trans,
a.trans:hover img,
a.trans:hover {
    opacity: 1;
}
.container {
    margin: 0 auto;
}
.pagetop {
    display: block;
}
.pagetop a{
    display: block;
    background: #f0f0f0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    font-size: 1.4rem;
    text-align: center;
    padding: 10px;
}
.pagetop .icon-arw-top {
    margin-left: 15px;
}
.icon-arw-top {
    display: inline-block;
    vertical-align: -2px;
    border-top: 1px solid #454442;
    border-left: 1px solid #454442;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.icon-arw-right {
    position: absolute;
    top: 50%;
    right: 14px;
    display: inline-block;
    border-top: 1px solid #454442;
    border-left: 1px solid #454442;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    margin-top: -4px;
}
.is-pc {
    display: none;
}
.is-mobile {
    display: block;
}

/*----------------------------------------------------
 header
----------------------------------------------------*/
.header {
    position: relative;
    width: 100%;
    height: 55px;
    margin: 0 auto;
    margin-bottom: 0;
}
.header-inner {
    position: static;
    text-align: left;
    padding: 0;
}
.header-logo {
    display: inline-block;
    width: 350px;
    height: 55px;
    line-height: 55px;
    margin-left: 10px;
}
.header-logo img {
    width: 120px;
    vertical-align: middle;
}
.header-logo img.sp-logo-banner {
    margin-left:10px;
    margin-top: 5px;
    width: 45%;
}
img.pc-logo-banner {
    display: none;
}
img.pc-logo {
    display: none;
}
.accordion-container .accordion-title {
    position: absolute;
    top: 19px;
    font-size: 1.8rem;
    font-weight: bold;
    color: #808080;
    right: 38%;
    display: none;
}
.accordion-container .accordion-title::after {
    content: "";
    position: absolute;
    top: 12px;
    left: 115%;
    font-size: 1.8rem;
    border: 8px solid transparent;
    border-top-color: #808080;
}
.accordion-container .accordion-title.open::after {
    content: "";
    position: absolute;
    top: 12px;
    left: 115%;
    font-size: 1.8rem;
    border: 8px solid transparent;
    border-bottom-color: #808080;
}
.accordion-content {
    top: 45px;
    opacity: 0.8;
    z-index: 20;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    width: 200px;
    right: 25%;
    text-align: center;
    font-size: 1.8rem;
    background-color: #f5f5f5;
    position: fixed;
 }
 .accordion-container {
    width: 300px;
    margin: 0 auto;
 }
 li.list {
    box-sizing: border-box;
    height: 40px;
    border: thin solid #B0C0B0;
}
li.list:hover {
    background-color: #B0C0B0;
}
.list a {
    vertical-align: middle;
    font-weight: bold;
}
.header-menu-column {
    display: none;
}
.header-search-pc {
    display: none;
}

/*----------------------------------------------------
 menu
----------------------------------------------------*/
.header-menu-sp {
    display: block;
}
.header-menu-sp-inner {
    display: none;
    position: absolute;
    top: 56px;
    width: 100%;
    height: auto;
    z-index: 9999;
    text-align: right;
}
.icon-menu {
    position: absolute;
    top: 19px;
    right: 10px;
    display: block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-menu.png) no-repeat 0 0;
    background-size: 24px auto;
    width: 24px;
    height: 21px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
}
.btn-close {
    display: block;
    background: #cdcdcd;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    height: 44px;
    line-height: 44px;
    text-align: center;
    margin-left: 57%;
}
/* search */
.header-search-sp {
    margin: 15px 3%;
}
.header-search-sp .search-form {
    position: relative;
    display: table;
    width: 100%;
    height: 50px;
    line-height: 50px;
}
.header-search-sp .search-form-input {
    display: inline-block;
    vertical-align: top;
    border: 1px solid #7baf27;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-size: 1.5rem;
    width: 76%;
    height: 50px;
    outline: none;
    padding-left: 5px;
    margin-right: 2%;
}
.header-search-sp .search-form-submit {
    display: inline-block;
    background: #7baf27;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    width: 22%;
    height: 50px;
    line-height: 50px;
    outline: none;
}
/* Tag */
.header-tag-sp {
    margin: 10px 0 15px;
}
.header-tag-sp .tag-list {
    margin: 20px 3%;
}
.tag-list {
    margin: 20px 0;
}
.tag-list-item {
    margin: 0 10px 10px 0;
}
.tag-list-item-box {
    font-size: 1.5rem;
}
/* category */
.header-nav {
    display: inline-block;
    width: 43%;
    background: #fff;
}
.menu-title {
    border-bottom: 2px solid #454442;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 2.6;
    text-align: left;
    padding: 0 12px;
}
.header-nav-list-item {
    display: inline-block;
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    background-color: #f5f5f5;
}
.header-nav-list-item.w100 {
    width: 100%;
}
.header-nav-list-item:nth-child(2n) {
    border-left: 1px solid #dedede;
}
.header-nav-list-item-box {
    display: block;
    text-align: left;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 44px;
    padding: 0 12px;
}
li.sp-category-list {
    display: inline-block;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #dedede;
    box-sizing: border-box;
}
a.category-list-item-box {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    line-height: 44px;
}

/*----------------------------------------------------
 navi
----------------------------------------------------*/
.caTitle {
    margin: 0 10px 10px;
    font-size: 1.5rem;
}
.top-banner {
    margin-bottom:10px;
}
.swipe-list-tab-list {
    position: absolute;
    top: 0;
    left: 0;
    display: table;
    table-layout: fixed;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
}
.swipe-list-tab-item {
    position: relative;
    z-index: 2;
    height: 35px;
    text-align: center;
    white-space: nowrap;
    margin: 3px 0 0;
}
.swipe-list-tab-item:before {
    content: '';
    display: block;
    width: 80px;
    height: 0;
}
.swipe-list-tab-item.ca00:before,
.swipe-list-tab-item.ca01:before,
.swipe-list-tab-item.video:before {
    width: 70px;
}
.swipe-list-tab-item.ca02:before,
.swipe-list-tab-item.ca03:before,
.swipe-list-tab-item.ca09:before {
    width: 100px;
}
.swipe-list-tab-item.ca04:before {
    width: 90px;
}
.swipe-list-tab-item.ca05:before {
    width: 160px;
}
.swipe-list-tab-item.ca06:before,
.swipe-list-tab-item.ca08:before,
.swipe-list-tab-item.chilltv:before {
    width: 120px;
}
.swipe-list-tab-item.ca07:before {
    width: 140px;
}
.swipe-list-tab-item.chilltv {
    margin: 0px 0 0;
    padding-bottom: 3px;
}
.swipe-list-tab-item a {
    position: relative;
    display: block;
    color: #999;
    font-weight: 700;
    height: 28px;
    padding: 7px 0 0;
}
.swipe-list-tab-item a img {
    max-width: initial;
}
.swipe-list-tab-item span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 28px;
    color: #fff;
}

.swipe-list-tab-item a:hover span {
    border-bottom: 5px solid #fff;
    padding-bottom: 5px;
    box-sizing: border-box;
    color: #fff;
}
.swipe-list-tab-item.current a span {
    border-bottom: 5px solid #fff;
    box-sizing: border-box;
    padding-bottom: 5px;
}
.swipe-list-tab-item.chilltv a:hover span {
    border-bottom: 0px solid #fff;
    padding-bottom: 5px;
    box-sizing: border-box;
    color: #fff;
}
.swipe-list-tab-item.chilltv.current a span {
    border-bottom: 0px solid #fff;
    box-sizing: border-box;
    padding-bottom: 5px;
}

/*----------------------------------------------------
 media
----------------------------------------------------*/
.media {
    width: 100%;
}
.media-title {
    border-bottom: 2px solid #454442;
    line-height: 2.6;
    padding: 0;
}
.media-list {
    border-collapse: initial;
    border-spacing: initial;
    width: 94%;
    margin: 20px auto 30px;
}
.media-list-item {
    width: initial;
    padding: 10px 15px;
}
.media-list-item img {
    max-width: 90px;
    max-height: 22px;
}

/*----------------------------------------------------
 footer
----------------------------------------------------*/
.footer-wrap {
    padding: 0;
}
.footer {
    width: 100%;
}
.footer-inner {
    margin-bottom: 0;
    width: 100%;
}
.footer-inner .inner-cont.sns,
.footer-inner .inner-cont .lead,
.footer-inner .inner-cont .lead-text,
.footer-inner .inner-cont .category {
    display: none;
}
.footer-inner .inner-cont .outline {
    border: none;
    width: 94%;
    padding: 0 3%;
}

.footer-sns-follow {
    margin: 0 auto;
    overflow: hidden;
    padding: 0 0 5px;
}
.footer-sns-follow:after {
    content: "";
    display: block;
    clear: both;
}
.sns-follow-item {
    float: left;
    margin-bottom: 20px;
}
.footer-sns-follow dl {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.footer-sns-follow dl:after {
    content: "";
    display: block;
    clear: both;
}
.footer-sns-follow dd {
    display: inline-block;
    width: 25%;
    font-size: 10px;
    position: relative;
}
.footer-sns-follow dd img {
    width: 60px;
}
.sns-follow-item {
    float: left;
    margin-bottom: 20px;
}
/* clearfix */
.sns-follow-item img {
    max-width: 100%;
    height : auto;
}
.sns-follow .text {
    font-size: 16px;
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 15px;
}
.footer-title {
    display: none;
}
.outline-list {
    display: table;
    width: 100%;
    text-align: center;
    margin: 10px 0;
}
.outline-list-item {
    display: inline-block;
    font-size: 1.3rem;
    line-height: 1;
}
.outline-list-item a {
    display: block;
    padding: 10px;
}
.copy {
    text-align: center;
    padding: 12px 0;
}
.aalogo img {
    max-width: 50px;
}

/*----------------------------------------------------
 ad
----------------------------------------------------*/
.ad-box {
    min-height: 200px;
    margin-bottom: 30px;
}
.ad-box-pc {
    display: none;
}
.ad-box-pc-no-margin {
    display: none;
}
.ad-box-sp {
    display: block;
    margin: 0 3% 30px;
}
.ad-box-sp-no-margin {
    display: block;
}
.main-cont .ad-box-sp {
    margin: 0 3% 20px;
}
.article-wrap .ad-box-sp {
    margin: 0 auto 30px;
    text-align: center;
}
.article-bottom-rec {
    width: 300px;
}
/* 誘導枠 */
.main .pickup-item-box {
    max-width: 100%;
    max-height: inherit;
}
.sub-cont .pickup-item-box {
    max-width: 100%;
}
/* リスト */
.ad-list {
    display: table;
    width: 100%;
}
.ad-list-item {
    width: 48.1%;
}
.ad-list-item:not(:last-child) {
    margin-right: 3.8%;
}
/* yahoo infeed */
.yads_ad_responsive_small {
    line-height: 1.5;
}
.yads_ad_responsive_small a {
    display: block;
}
.yads_ad_responsive_small .yads_ad_img {
    display: block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    margin-bottom: 7px;
}
.yads_ad_responsive_small .yads_ad_title {
    font-weight: bold;
}
.yads_ad_responsive_small .yads_ad_adv {
    font-size: 1.1rem;
    margin-top: 5px;
}
.yads_ad_responsive_small .yads_ad_info {
    margin: 3px 0;
    text-align: right;
    font-size: 1.1rem;
}
.yads_ad_responsive_small .yads_ad_info a {
    color: #999;
}
.yads_ad_responsive_small .yads_ad_info a span {
    margin-right: 2px;
}
.yads_ad_responsive_small .yads_ad_info img {
    opacity: 0.6;
    vertical-align: -2px;
}

/*----------------------------------------------------
 common
----------------------------------------------------*/
.content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding-top: 5px;
}
.main {
    float: none;
    width: 100%;
}

/*----------------------------------------------------
 右カラム
----------------------------------------------------*/
.sub-cont-wrap {
    float: none;
    width: 100%;
}
.sub-cont {
    float: none;
    width: 94%;
    margin: 0 3% 30px;
}
.sub-cont-title {
    border-bottom: 2px solid #454442;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    line-height: 42px;
    margin-bottom: 25px;
}

/*----------------------------------------------------
 Ranking、Popular
----------------------------------------------------*/
.ranking,
.tag {
    display: none;
}

/*----------------------------------------------------
 list
----------------------------------------------------*/
.article-list-wrap {
    margin: 0 3%;
}
.article-list {
    display: table;
    width: 100%;
}
.article-list-item,
.article-list-item:nth-child(3n) {
    width: 49%;
    margin-right: 2%;
}
.article-list-item:nth-child(2n) {
    margin-right: 0;
}
.article-thum {
    width: 100%;
    height: 0;
    padding-bottom: 56%;
}
.article-thum iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.article-list-title {
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 10px;
}
/* label */
.label-gray {
    width: 80px;
    height: 20px;
    line-height: 20px;
    font-size: 1.2rem;
    white-space: nowrap;
}
/* movie */
.icon-video {
    width: 36px;
    height: 36px;
    margin-top: -18px;
    margin-left: -18px;
}
.icon-video:before {
    border-width: 9px 0 9px 16px;
    margin-top: -9px;
    margin-left: -6px;
}

/*----------------------------------------------------
 top
----------------------------------------------------*/
/* slider */
.main-top {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto 5px;
}
.top-article-list-item-box {
    width: 100%;
    height: 0;
    padding-bottom: 56%;
}
.main-top .top-article-title-wrap,
.list-top .top-article-title-wrap {
    width: 100%;
    padding: 0;
}
.top-article-title-wrap {
    padding: 16px 20px !important;
}
.top-article-title {
    font-size: 1.7rem;
    height: 45px;
    padding: 30px 8px 10px;
    line-height: 22px;
}

/*----------------------------------------------------
 article
----------------------------------------------------*/
.article-wrap {
    border-top: none;
    border-bottom: 1px solid #dedede;
    width: 94%;
    margin: 0 3% 25px;
}
.article-wrap:last-child {
    border-bottom: none;
}
/* 記事ヘッダー */
.citrus-h1 {
    font-size: 1.9rem;
    margin: 0 0 15px;
}
.article-bottom-aside {
    margin-top: -20px;
    margin-bottom: 20px;
}
.label-wrap .label-gray {
    font-size: 1.3rem;
    padding: 2px 8px;
    margin-right: 8px;
    margin-bottom: 3px;
}
.author {
    font-size: 1.3rem;
    margin: 0 5px 0 0;
}
.author img{
    margin-bottom: 5px;
}
.article-date {
    font-size: 1.3rem;
}
/* 記事 */
.article-content h2 {
    font-size: 2.1rem;
}
.article-content h3 {
    font-size: 1.9rem;
}
.article-content h4 {
    font-size: 1.2rem;
}

/* 記事画像 */
.article-content > img {
    max-width: 100%;
    max-height: 100%;
}
.article-img-box02 {
    width: 100%;
}
.article-img-cap {
    font-size: 1.3rem;
    width: 96%;
    padding: 10px 0 15px;
}
.article-img-box02.right {
    margin-left: 0;
}
.article-img-box02.left {
    margin-right: 0;
}

.citrus-right-image {
    float: none;
    max-width: none;
    margin-left: 0;
}
.citrus-left-image {
    float: none;
    max-width: none;
    margin-right: 0;
}
.citrus-image {
    max-width: none;
}
.citrus-caption {
    font-size: 1.3rem;
    max-width: none;
    padding: 10px 0 15px;
    margin: 0;
}
/* 記事動画 */
.article-video-wrap {
    position: relative;
    padding-top: 100%;
}
.article-video-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
/* 続きを読む */
.article-body {
    height: 900px;
}
.article-body.videosource {
    height: 700px;
}
.article-body:after {
    width: 100%;
}
.article-readmore {
    margin: 0;
}
.article-readmore-btn {
    font-size: 1.7rem;
    width: 100%;
    height: 55px;
    line-height: 55px;
}
/* 引用 */
.article blockquote {
    display: block;
    position: relative;
    background: #eee;
    padding: 20px 35px;
    margin-bottom: 30px;
}
.article blockquote:before {
    top: 15px;
    left: 10px;
    background-size: 25px 19px;
    width: 25px;
    height: 19px;
}
.article blockquote:after {
    bottom: 15px;
    right: 10px;
    background-size: 25px 19px;
    width: 25px;
    height: 19px;
}
/* SNS */
.article-sns {
    display: block;
    width: 100%;
}
.fb-good {
    background: #fff;
    width: 100%;
    height: auto;
    padding: 0;
    margin-right: 0;
    margin-bottom: 15px;
}
.fb-good .title {
    font-size: 1.6rem;
    margin-bottom: 10px;
}
.fb-good .text {
    font-size: 1.4rem;
    margin-top: 10px;
}
.fb-good .twitter-follow {
    margin: 0px;
    z-index: 10;
    height: 20px;
    display: inline-block;
    vertical-align: bottom;
}
.sns-list {
    display: table;
    width: 100%;
}
.sns-list .fb {
    width: 31%;
    margin-right: 3.5%;
}
.sns-list .fb a {
    font-size: 1.2rem;
    width: 100%;
    height: 50px;
    padding: 10px 0;
}
.sns-list .twi {
    width: 31%;
    margin-right: 3.5%;
}
.sns-list .twi a {
    font-size: 1.2rem;
    width: 100%;
    height: 50px;
    padding: 10px 0;
}
.sns-list .line {
    display: inline-block;
    font-size: 1.2rem;
    width: 31%;
}
.sns-list .line a {
    display: block;
    background: #00c300;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    height: 50px;
    padding: 10px 0;
}
.icon-fb {
    margin: 0 auto 5px;
}
.icon-twi {
    margin: 0 auto 5px;
}
.icon-line {
    display: block;
    background: transparent url(//img.aacdn.jp/app/citrus-front/images/icon-line.png) no-repeat center center;
    background-size: 31px 29px;
    width: 33px;
    height: 33px;
    margin: 0 auto 4px;
}
/* 媒体紹介 */
.article-media-img-wrap {
    width: 24%;
    padding: 0 3%;
}
.article-media-detail {
    width: 64%;
    padding: 0 3%;
}
/* 誘導枠 */
.induction-article-img-wrap {
    width: 33%;
    padding: 0 1%;
}
.induction-article-detail {
    padding: 0 1%;
}
/* 矢印 */
.icon-arw-blue {
    vertical-align: -3px;
}

/* 追従メニュー */
.social-links-sp {
    background: url(//img.aacdn.jp/app/citrus-front/images/bg_black_snsp.png);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px;
    z-index: 9999;
}

.social-links-sp .btn-logo-sp {
    float: left;
    display: inline-block;
    position: relative;
    z-index: 99;
}

.social-links-sp .fbiine {
    float: right;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 10px;
    padding-top: 5px;
    padding-right: 5px;
}

.social-links-sp .social-btns {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 5px 0;
    width: 70%;
}

.social-links-sp .social-btns li:not(:last-of-type) {
    margin-right: 15px;
}

.social-links-sp .title {
    padding-left: 0px !important;
}

.social-links-sp .title li:not(:last-of-type) {
    color: #fff;
    word-break: break-all;
}

.social-links-sp .title li:last-of-type {
    margin-right: 10px;
    color: #fff;
    width: 50%;
    word-break: break-all;
    font-size: x-small;
}

.social-links-sp .next-btn {
    width: 44px;
}

.social-links-sp .next-image {
    width: 80px;
}

.social-links-sp .title a {
    color: #fff;
    display: block;
}

.social-links-sp .scroll {
    float: right;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 10px;
    padding-top: 4px;
    padding-right: 5px;
}

/*----------------------------------------------------
 category
----------------------------------------------------*/
.content-inner {
    margin: 0 3%;
}
.list-top {
    padding: 15px 0;
    margin-bottom: 15px;
}
.list-top-title {
    font-size: 1.9rem;
}
.list-top-inner .top-article-list-item {
    width: 48.1%;
    float: right;
}
.list-top-inner .top-article-list-item:first-child {
    margin-right: 3.8%;
    float: left;
    overflow: hidden;
}
.list-top-inner .top-article-list-item-box {
    width: 100%;
    height: 0;
    padding-bottom: 56%;
}

/*----------------------------------------------------
 list - media
----------------------------------------------------*/
.media-box {
    width: 100%;
    padding: 20px 15px;
    margin-bottom: 15px;
}
.media-box-inner-left {
    display: block !important;
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 5px;
}
.media-box-inner-right {
    display: block !important;
    width: 100%;
    font-size: 1.5rem;
}
.media-box-title {
    font-size: 1.6rem;
    text-align: center;
}
.media-box-position {
    text-align: center;
}
.media-box-inner-author-image{
    height: 150px;
    width: 150px;
    border-radius:50%;
    -ms-border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    background-size: cover;
    border:1px solid #e6e6e6;
}
/*----------------------------------------------------
 search
----------------------------------------------------*/
.search-content {
    width: 94%;
    margin: 0 3%;
}
.search-box {
    margin: 15px 3%;
}
.search-box .search-form {
    position: relative;
    display: table;
    width: 100%;
    height: 50px;
    line-height: 50px;
}
.search-box .search-form-input {
    display: inline-block;
    vertical-align: top;
    border: 1px solid #7baf27;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-size: 1.5rem;
    width: 76%;
    height: 50px;
    line-height: 50px;
    outline: none;
    padding-left: 5px;
    margin-right: 2%;
}
.search-box .search-form-submit {
    display: inline-block;
    background: #7baf27;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    width: 22%;
    height: 50px;
    line-height: 50px;
    outline: none;
}

/*----------------------------------------------------
 運営組織
----------------------------------------------------*/
.title-box-bar {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 35px;
    padding: 0 3%;
    margin-bottom: 20px;
}
.main-cont-inner {
    margin: 0 14px;
}
.about-title {
    font-size: 1.7rem;
    margin-bottom: 15px;
}
.company-info-title {
    font-size: 1.6rem;
    margin-bottom: 5px;
}
.list-table {
    border: none;
}
.list-table li {
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
}
.list-table li .th {
    display: block;
    background-color: #f0f0f0;
    border-bottom: 1px solid #dedede;
    padding: 6px 3%;
    font-weight: bold;
    width: 94%;
}
.list-table li .td {
    display: block;
    background-color: #fff;
    border-left: none;
    border-bottom: 1px solid #dedede;
    padding: 12px 3%;
    width: 94%;
}
.list-table .column2-left {
    width: 72%;
    vertical-align: middle;
}
.list-table .column2-right {
    width: 28%;
    vertical-align: middle;
}
.list-table li .td li:first-child {
    border-top: none !important;
}
.list-table li .td li {
    border-left: none !important;
    border-right: none !important;
}
.list-table li:first-child {
    border-top: 1px solid #dedede;
}

/*----------------------------------------------------
 プライバシーポリシー、著作権・商標・免責事項
----------------------------------------------------*/
.content-box-list dt {
    width: 10%;
    text-align: center;
}
.content-box-list dd {
    width: 90%;
    margin-bottom: 7px;
}

/*----------------------------------------------------
 404ページ
----------------------------------------------------*/
.forbidden {
    font-size: 1.6rem;
    margin: 50px 3% 0;
}
.forbidden-title {
    font-size: 1.9rem;
    margin: 0 3% 30px;
}
.btn-green {
    font-size: 1.9rem;
    width: 50%;
    margin: 0 auto 30px;
}
.btn-green span {
    font-size: 1.5rem;
}
.forbidden-text br {
    display: none;
}
.logo-image {
    width: 100px;
    margin: 20px 0;
}
.forbidden .copy{
    text-align: center;
}
.authorlist { padding-top: 10px; }
.authorlist:after { content: ""; display: block; clear: both; }
.authorlist .caption { font-size: 2rem; padding: 0 10px 15px; text-align: left; line-height: 1.5; }
.authorlist .inr { display: block; }
.authorlist .inr object { display: none; width: 25%; overflow: hidden; float: left; }
.authorlist .inr object:nth-of-type(1),
.authorlist .inr object:nth-of-type(2),
.authorlist .inr object:nth-of-type(3),
.authorlist .inr object:nth-of-type(4),
.authorlist .inr object:nth-of-type(5),
.authorlist .inr object:nth-of-type(6),
.authorlist .inr object:nth-of-type(7),
.authorlist .inr object:nth-of-type(8) { display: block; }

.authorlist .inr object span { width: 70px; height: 70px; }
.authorlist .inr object span img { width: 75px; height: 75px; }
.videolist { padding: 10px 0 10px; margin: 0 0 15px; }
.videolist .caption { padding: 0 0 10px; font-size: 2rem; line-height: 1.3; color: #7baf27;}
.videolist .cont { width: auto; margin: 0 0 10px; display: block; }
.videolist .cont dl { height: auto; width: 100%; padding-bottom:56%; position: relative; }
.videolist .cont dl dd { height: 0; padding-bottom:56%; width: 100%; }
.videolist ul { box-sizing: border-box; padding: 0 10px 0; }
.videolist ul a { min-width: 1px; display: block; }
.slick-prev { left: 0; margin: -30px 0 0; }
.slick-next { right: 0; margin: -30px 0 0; }
.slick-prev:hover,
.slick-next:hover { width: 18px; }

.slider.categories { display: none; }
.categoriesmenu { margin: 0 10px 10px; padding: 0; display: block; }
.categoriesmenu dt { margin: 0 0 10px; background: #fafafa; }
.categoriesmenu dd { font-weight: 700; line-height: 1.5; margin-bottom: 20px; }
.categoriesCover { margin:0 0 -5px; }
.categoriesCover .inr { display: block; }
.categoriesCover .inr .cont { display: block; margin: 0 0 10px; }
.slick-current + .top-article-list .top-article-title-wrap { opacity: 1 !important; }

/*----------------------------------------------------
 dfp-contents
----------------------------------------------------*/
.dfp-contents-fixed {
    font-family: "メイリオ",Meiryo,YuGothic,"游ゴシック","Hiragino Kaku Gothic Pro",Osaka,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
    font-size: 11px;
}
.dfp-contents {
}

.paginate-unit {
    width: 100%;
    margin-bottom: 90px;
}

/* PREV */
.paginate .prev {
    font-size: 1.1rem;
}

/* NEXT */
.paginate .next {
    font-size: 1.1rem;
}

.paginate-article-unit {
    width: 100%;
}

/* NEXT */
.paginate-article-unit .next {
    font-size: 1.5rem;
}

/* PREV */
.paginate-article .prev {
    font-size: 1.2rem;
}

/* NEXT */
.paginate-article .next {
    font-size: 1.2rem;
}

}
/* iphone6+ */
@media screen and (max-width:414px) {
.media-list-item {
    width: 33.3333%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}


}


@media screen and (max-width:320px) {
/* 記事ヘッダー */
.citrus-h1 {
    font-size: 1.9rem;
    margin: 0 0 15px;
}
.label-wrap .label-gray {
    font-size: 1.2rem;
    padding: 2px 6px;
    margin-right: 6px;
}
.author {
    font-size: 1.2rem;
    margin-bottom: 0;
}
.author img{
    margin-bottom: 5px;
}
.article-date {
    font-size: 1.2rem;
}
.social-links-sp .social-btns{
    justify-content: flex-start;
    padding-left: 60px;
}

}

/*----------------------------------------------------
 dfp-contents
----------------------------------------------------*/
.dfp-contents-fixed {
    position: fixed;
    width: 100%;
    bottom: 0;
    color: #fff;
    background: rgba(0,0,0,0.8);
    z-index: 9999;
}
.dfp-contents {
    line-height: 1.3;
    position: relative;
    margin: 0 auto;
    padding: 10px 40px 10px 15px;
    position: relative;
    max-width: 1024px;
}
.dfp-contents a {
    color: #85aed7;
    text-decoration: underline;
}
.dfp-contents-close {
    font-family: sans-serif;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 22px;
    height: 22px;
    background: #999;
    color: #fff;
    text-align: center;
    line-height: 22px;
    font-size: 21px;
    cursor: pointer;
}
/* SP向け */
@media screen and (max-width: 1023px) {
    .dfp-contents-fixed {
        font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
        font-size: 11px;
    }
}
